/* eslint-disable no-irregular-whitespace */
/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import styled from 'styled-components'
import CallToAction, { StyledCallToAction } from '../components/CallToAction'
import { breakpoints, colors, layout } from '../theme'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

const StyledCgvPage = styled.section`
  .page-content {
    margin-top: ${layout.navbarHeight.normal}px;
  }

  @media (max-width: ${breakpoints.mobile}px) {
    .page-content {
      margin-top: ${layout.navbarHeight.mobile}px;
    }
  }

  ${StyledCallToAction} {
    margin-bottom: 50px;
    height: 350px;

    & > .wrapper {
      max-width: ${layout.maxWidth}px;
      width: ${layout.Layout};
      margin: 50px auto;
      text-align: center;

      h1 {
        max-width: 800px;
      }
    }
  }

  .wrapper-cgv {
    max-width: ${layout.maxWidth}px;
    width: ${layout.width};
    margin: 0 auto;
    margin-bottom: 50px;

    h3 {
      color: ${colors.brand.normal};
      padding: 5px 0;
      border-radius: 50px;
      margin-top: 50px;
      margin-bottom: 15px;
      font-size: 1.2rem;
    }
    p {
      margin: 10px 0;
    }
  }

  @media (max-width: ${breakpoints.tablet}px) {
    .wrapper {
      padding: 0;
    }
  }
`

class CgvPage extends React.Component {
  render() {
    return (
      <StyledCgvPage>
        <Navbar />
        <div className="page-content">
          <CallToAction
            text={`
              Conditions générales de vente et d’utilisation du site
              `}
            backgroundColor={`rgb(224,238,246)`}
            buttonText={null}
            className="header-cta"
          />
          <div className="wrapper-cgv">
            <p>
              Veuillez lire attentivement les conditions générales de vente et
              d’utilisation du site monbanquet. Vous pouvez également
              sauvegarder ou imprimer le texte intégral.
            </p>

            <p>
              Les présentes conditions générales d’utilisation et de vente
              (ci-après, désignées les « Conditions Générales ») régissent les
              conditions d’utilisation et de vente du service « MONBANQUET »
              (ci-après, désigné le « Service MONBANQUET ») proposé par le site
              www.monbanquet (ci-après, désigné le « Site  »).
            </p>

            <p>
              Le Service MONBANQUET est proposé par la société JEG, société par
              actions simplifiée, dont le siège social est situé 23, Avenue René
              Cassin - 77127 Lieusaint, immatriculée au Registre du Commerce et
              des Sociétés de Melun sous le n°803 062 611.
            </p>

            <p>
              Le Service MONBANQUET est un service en ligne de traiteur
              sur-mesure adapté à tous les budgets permettant ainsi de faciliter
              l’accès au service de traiteur. Il propose la vente et la
              livraison de buffets, boissons, vaisselle jetable, ainsi que des
              services de maîtres d’hôtel. MONBANQUET ne fabrique pas les
              produits vendus, mais se fournit auprès d’artisans et fournisseurs
              sélectionnés.
            </p>

            <p>
              Le Site est édité par la société JEG et hébergé par la société
              Netlify.
            </p>

            <p>
              Madame Juliette GROUT est la Directrice de la Publication du site.
            </p>

            <p>
              Il est possible de contacter la société à l'adresse indiquée en
              tête des présentes, ou encore par courrier électronique à
              l’adresse contact@monbanquet.fr et par téléphone au +33 1 84 88 94
              73.
            </p>

            <p>
              Les présentes Conditions Générales définissent l’intégralité des
              droits et obligations des Utilisateurs et Clients et de l’Equipe
              MONBANQUET dans le cadre de la vente en ligne des prestations
              proposées sur le Site.
            </p>

            <p>
              Avant l’établissement de tout Devis et la validation de toute
              Commande, le Client est informé qu’il accepte les présentes
              Conditions Générales en cochant la case « J’accepte les Conditions
              Générales d’Utilisation et de Vente » figurant sur le formulaire
              de demande de devis. En cliquant, le Client reconnaît avoir lu et
              accepté sans réserve les Conditions Générales qui prévalent sur
              tout autre document émanant du Client.
            </p>

            <p>
              Les présentes Conditions Générales sont applicables pendant toute
              la durée d’utilisation des Services proposés.
            </p>

            <p>
              Les présentes Conditions Générales peuvent être modifiées à tout
              moment et sans préavis, les conditions applicables étant celles en
              vigueur à la date de la Commande, c’est à dire de l’acceptation du
              Devis par le Client.
            </p>

            <h3>ARTICLE 1 - DÉFINITIONS</h3>

            <p>
              Dans les Conditions Générales, les mots ou expressions commençant
              avec une majuscule ont la signification qui suit :
            </p>

            <p>
              • Artisan Partenaire : Tout artisan, boulanger, traiteur,
              détaillant, producteur partenaire du Site monbanquet.fr.
            </p>

            <p>
              • Client : toute personne qui demande un devis et/ou passe
              commande auprès de l’Equipe MONBANQUET.
            </p>

            <p>
              • Commande : désigne tout devis définitivement validé par l’Equipe
              MONBANQUET.
            </p>

            <p>
              • Devis : proposition(s) soumise(s) au Client en fonction des
              desiderata communiqués sur le Site. Le Devis émis est valable pour
              une durée de 15 jours minimum, une durée plus longue pouvant être
              prévue dans le devis. Une fois accepté par le Client, il
              constituera la Commande définitive.
            </p>

            <p>
              • Droit de Propriété Intellectuelle : ensemble des droits de
              propriété littéraire et artistique (droits d’auteur et droits
              voisins), de propriété industrielle (marques, dessins et modèles
              et brevets) prévus dans le Code de propriété intellectuelle et les
              Traités internationaux.
            </p>

            <p>
              • L’équipe MONBANQUET : Équipe en charge de l’établissement des
              Devis et de l’exécution de la Commande.
            </p>

            <p>
              • Service(s) : ensemble des services proposés sur le Site par
              l’Equipe MONBANQUET en application des présentes.
            </p>

            <p>
              • Site : ensemble des éléments structurant le site internet
              accessible à l’adresse monbanquet.fr ainsi que l’ensemble du
              contenu créé dont notamment : la charte graphique, les frames, les
              bannières, les animations flash et vidéo, le code source, code
              html et la programmation.
            </p>

            <p>
              • Utilisateur : toute personne qui visite une ou plusieurs pages
              du Site afin de consulter gratuitement les informations
              disponibles et offres émises sans demander de Devis ou passer
              Commande auprès de l’Equipe MONBANQUET.
            </p>

            <h3>ARTICLE 2 – INFORMATIONS ET DISPONIBILITE</h3>

            <p>
              L’Equipe MONBANQUET bénéficie d’un réseau d’artisans présents dans
              toute la France et propose, via son Site, des buffets froids
              élaborés par ses Artisans Partenaires.
            </p>

            <p>
              L’Equipe MONBANQUET se réserve le droit de modifier, à tout
              moment, le contenu des buffets proposés sur son Site. L’Equipe
              MONBANQUET ne peut également garantir à tout moment la
              disponibilité de chaque produit proposé sur le Site. Ainsi en cas
              de rupture de stock du produit souhaité, l’Equipe MONBANQUET
              contactera le Client dans les plus brefs délais afin de l’en
              informer et de convenir avec lui de remplacer le produit
              indisponible par un autre équivalent en qualité et prix.
            </p>

            <p>
              L’Equipe MONBANQUET fournit également les serviettes et plateaux
              jetables sur lesquels sont disposés les produits.
            </p>

            <p>
              La reprise des déchets n’est pas assurée par le Service
              MONBANQUET. Si cette reprise était demandée par le Client,
              celle-ci serait facturée ; de même pour l’installation du buffet.
            </p>

            <h3>ARTICLE 3 – UTILISATION DU SERVICE MONBANQUET</h3>

            <p>
              3.1 La navigation sur le Site est libre et gratuite. Pour accéder
              à l’ensemble des Services proposés, établir un Devis et passer une
              Commande, il suffit de remplir le formulaire proposé sur le Site,
              ou de prendre contact avec l’Equipe MONBANQUET par téléphone au
              numéro affiché directement sur le site, ou par e-mail à l’adresse
              affichée directement sur le site.
            </p>

            <p>
              3.2 L’Equipe MONBANQUET, avant d’établir un Devis, vous demandera
              de fournir obligatoirement les informations suivantes :
            </p>

            <p>• Nom</p>
            <p>• Adresse complète</p>
            <p>• Numéro de téléphone</p>
            <p>• Adresse mail</p>
            <p>• Nombre de couverts</p>
            <p>• Lieu, date et heure de la réception</p>
            <p>• Choix du buffet</p>

            <p>
              Le Client est personnellement responsable des informations
              communiquées et il doit immédiatement contacter l’Equipe
              MONBANQUET aux coordonnées mentionnées au préambule des présentes
              s’il remarque que certaines informations paraissent erronées.
            </p>

            <p>
              Une fois ces informations transmises, l’Equipe MONBANQUET, en
              accord avec ses Artisans Partenaires, proposera les différents
              buffets disponibles. L’Equipe MONBANQUET émettra alors un Devis
              valable pour une durée de 15 jours minimum.
            </p>

            <p>
              3.3 Les buffets proposés sont susceptibles d’évoluer et l’Equipe
              MONBANQUET se réserve le droit de proposer sur son Site tout
              Service qu’elle jugera utile, sous une forme et selon les
              fonctionnalités et moyens techniques qu’elle estimera les plus
              appropriés pour rendre lesdits Services. Les buffets proposés sur
              le site ww.monbanquet.fr sont susceptibles d’être modifiées et
              adaptées à tout moment et l’Equipe MONBANQUET s’engage à proposer
              uniquement des produits conformes à la législation française et
              aux normes applicables en France.
            </p>

            <h3>ARTICLE 4 – COMMANDES /DEVIS</h3>

            <p>
              4.1 Le Site donne accès aux Services MONBANQUET présentés par
              l’Equipe MONBANQUET. Tout Devis ou Commande suppose l’adhésion du
              Client sans restriction ni réserve aux Conditions Générales.
            </p>

            <p>
              4.2 A la demande du Client, l’Equipe MONBANQUET transmettra un
              Devis établi sur la base des informations fournies.
            </p>

            <p>
              Le Devis est gratuit et adressé au Client par tout moyen. Il
              précise notamment les modalités de détermination du prix ainsi que
              les délais de livraison du Service. L’Equipe MONBANQUET se réserve
              le droit de refuser de traiter toute commande à livrer dans un
              délai inférieur à 48h.
            </p>

            <p>
              4.3 Pour confirmer sa Commande de manière ferme et définitive, le
              Client doit soit cliquer sur le bouton “Accepter la proposition” à
              la réception du devis, ou retourner le Devis daté et signé avec la
              mention « bon pour accord » à l’Equipe MONBANQUET.
            </p>

            <p>
              A défaut de réception de l’acceptation du Devis dans le délai
              prévu (ou à défaut de mention, dans un délai de 15 jours),
              l’Equipe MONBANQUET se réserve le droit de ne pas exécuter sa
              prestation. A défaut de réception du Devis signé dans un délai
              d’un mois à compter de sa date d’envoi, le Devis est réputé caduc.
            </p>

            <p>
              4.5 En cas d’annulation d’une Commande d’un montant inférieur à 5
              000€ par le Client après signature du Devis, pour quelque raison
              que ce soit, hormis la force majeure, l’annulation est gratuite
              jusqu’à une semaine avant l’événement. Au delà de 5 000€,
              l’annulation est gratuite jusqu’à deux semaines avant événement.
              Dans ces deux cas, MONBANQUET procèdera soit au remboursement
              intégral de l’acompte versé, soit adressera au Client une facture
              d’Avoir pour un prochain Devis (dans le cas d’un événement reporté
              à plus tard).
            </p>

            <p>
              Pour une annulation intervenant entre 2 jours ouvrés et une
              semaine (ou deux semaines en fonction du montant de la Commande),
              50% du prix payé sera de plein droit acquis à l’Equipe MONBANQUET
              et ne pourra donner lieu à un quelconque remboursement.
            </p>

            <p>
              Une commande annulée moins de 2 jours ouvrés avant l’événement
              devra être payée dans sa totalité.
            </p>

            <p>
              4.6 Pour toute demande de diminution de quantité portant sur une
              commande de plus de 200 personnes, la baisse du nombre de convives
              ne pourra excéder 20%, et ce dans un délai d’au moins 7 jours
              avant la date de l’événement.
            </p>

            <h3>ARTICLE 5 – TARIFS PRATIQUÉS</h3>

            <p>
              5.1 Les prix des produits peuvent évoluer en fonction du marché.
            </p>

            <p>
              Les Services seront fournis aux tarifs mentionnés sur le Devis.
              Ils s’entendent nets et la mention HT ou TTC sera précisée
              directement sur le Site et sur le Devis.
            </p>

            <p>
              5.3 L’Equipe MONBANQUET sera en droit, après avoir informé le
              Client, de majorer ses tarifs et/ou de ne pas procéder à la
              livraison à la date indiquée sur le Devis dans les cas où le
              Client, postérieurement à la signature du Devis, souhaiterait
              modifier le contenu du buffet. Le cas échéant, un Devis
              complémentaire, ou un nouveau Devis, pourra être émis.
            </p>

            <p>
              5.4 Toute décision de remise, de réduction ou d'application de
              tarifs dégressifs, selon un pourcentage ou un forfait, demeure à
              la seule discrétion de l’Equipe MONBANQUET et ce, uniquement pour
              la Commande qui en est l'objet. Les remises ou rabais
              éventuellement accordés au Client ne sauraient en aucun cas faire
              naître un droit acquis pour des prestations ultérieures.
            </p>

            <h3>ARTICLE 6 – CONDITIONS ET DELAIS DE REGLEMENT</h3>

            <p>6.1 Conditions et délais de règlement</p>

            <p>
              Pour une commande d’un montant supérieur à 500€, l’acompte de 50%
              du prix des offres présentées sur le site www.monbanquet.fr sera
              réglé après acceptation du Devis présenté, c’est-à-dire au jour de
              la validation de la Commande.
            </p>

            <p>
              Pour une commande d'un montant inférieur ou égal à 500€, le devis
              devra être réglé dans sa totalité pour être validé.
            </p>

            <p>
              En cas de non versement de cet acompte (pour une commande
              supérieure à 500€) ou solde (pour une commande inférieure ou égale
              à 500€) du prix dans le délai convenu entre les Parties dans le
              Devis, l’Equipe MONBANQUET sera en droit de ne pas exécuter la
              Commande.
            </p>

            <p>
              Le règlement des Commandes passées se fait par virement ou chèque
              à l’ordre de MONBANQUET. Dans ce cas, merci de bien vouloir
              contacter au préalable l’Equipe MONBANQUET par téléphone ou par
              e-mail.
            </p>

            <p>
              Le paiement peut également s’effectuer via une solution de
              paiement 100% sécurisée disponible sur le Site et qui accepte les
              règlements par carte bancaire : Carte Bleue, Visa™, MasterCard™.
              Le paiement se fera exclusivement en Euros ou en Dollars via
              STRIPE. Nous vous invitons préalablement à toute opération de
              paiement à lire les Conditions Générales de STRIPE accessibles à
              l’adresse www.stripelydia-app.com
            </p>

            <p>
              Chaque Client garantit disposer des autorisations éventuellement
              nécessaires pour utiliser le mode de paiement choisi et que cette
              carte bancaire donne accès à des fonds suffisants pour couvrir
              tous les coûts résultant du paiement du Service choisi.
            </p>

            <p>
              En cas de refus de la banque, la Commande sera automatiquement
              annulée et le Client sera prévenu par l'envoi d'un courrier
              électronique.
            </p>

            <p>
              L’Equipe MONBANQUET se réserve le droit d’annuler toute Commande
              en cas d’incident de paiement et aucun remboursement ne pourra
              avoir lieu dès lors que le travail fourni a été engagé dans les
              délais exprimés dans le Devis.
            </p>

            <p>
              Toute modification des prestations à la demande du client fera
              l’objet d’une facturation supplémentaire.
            </p>

            <p>
              Il est strictement interdit aux Clients de frauder ou de
              contourner le versement de notre Commission de quelque manière que
              ce soit.
            </p>

            <p>
              Le règlement des Commandes passées se fait par virement. Dans ce
              cas, merci de bien vouloir contacter au préalable l’Equipe
              MONBANQUET par téléphone ou par e-mail.
            </p>

            <p>6.2 Retard de paiement</p>

            <p>
              Pour les Clients professionnels, en cas de retard de paiement des
              sommes dues par le Client au-delà des délais fixés, conformément à
              l’article L441-6 du Code de commerce, sera due de plein droit à
              MONBANQUET une indemnité forfaitaire pour frais de recouvrement de
              40 € (décret n°2012-1115 du 2 octobre 2012).
            </p>

            <h3>ARTICLE 7 – LIVRAISON ET RÉCLAMATION</h3>

            <p>
              7.1 Une fois la Commande définitivement validée, l’Equipe
              MONBANQUET transmettra dans un délai de 10 jours, les informations
              relatives à la livraison du Buffet.
            </p>

            <p>
              7.2 Le Client devra s’assurer de la conformité du Buffet avec sa
              Commande. Toute réclamation devra, pour être prise en compte,
              intervenir au moment de la livraison. En cas de non conformité et
              vice apparent des produits livrés ou produits manquants, le Client
              devra faire état des réserves précises sur le bon de livraison.
              Dans ce cas, le Client pourra obtenir le remplacement gratuit.
            </p>

            <p>
              7.3 La livraison d’une Commande ne pourra être effectuée que dans
              les zones de livraison desservies par l’Equipe MONBANQUET.
            </p>

            <p>
              7.4. Le client s'engage à fournir le numéro de mobile d'un contact
              disponible durant le créneau de livraison qu'il a choisi et
              validé. Si le livreur n'arrive pas à joindre ce contact pendant
              plus de 15 minutes, Monbanquet se réserve le droit de facturer ce
              temps d'attente et de quitter les lieux au bout de 30 Minutes
              après avoir contacté le contact sur place.
            </p>

            <h3>ARTICLE 8 – HYGIENE</h3>

            <p>
              8.1 L’Equipe MONBANQUET veille à la stricte application des textes
              relatifs à la législation alimentaire.
            </p>

            <p>
              8.2 Tous les buffets proposés par l’Equipe MONBANQUET sont
              élaborés par les Artisans Partenaires à partir de produits frais.
              L’Equipe MONBANQUET porte une particulière attention à la qualité
              des produits sélectionnés chez ses Artisans Partenaires.
            </p>

            <p>
              8.3 Sauf indication contraire, les buffets sont servis et dégustés
              froids. A réception du buffet et avant toute dégustation, le
              Client devra le conserver dans un endroit sec et frais.
            </p>

            <p>
              Les buffets doivent être consommés le jour même. L’Equipe
              MONBANQUET ne saurait être tenue responsable de tout dommage lié à
              une mauvaise conservation et/ou usage des buffets livrés.
            </p>

            <h3>ARTICLE 8 BIS - COMMANDE ET LIVRAISON DE COLIS INDIVIDUELS</h3>

            <p>
              8 bis 1 Les commandes de colis envoyées par un service de
              livraison classique en température non dirigée doivent être
              validées au moins 7 Jours ouvrés avant la date de 8bis2 livraison
              souhaitée. Pour toutes les commandes de plus de 200 Colis, un
              délai plus long sera demandé et prévu au cas par cas avec le
              Client. Les commandes de colis envoyées par un service de
              livraison en température dirigé ( Produits frais et DLC Courtes )
              devront être validées avec communication des adresses au moins 15
              jours ouvrés avant la date de livraison souhaitée. Monbanquet se
              réserve la possibilité de réduire le délai en fonction des
              conditions de livraison de nos partenaires de livraison.
            </p>

            <p>
              8 bis 3 Les adresses de livraisons ne pourront être acceptées
              qu’en France Métropolitaine. Les adresses postales seront
              demandées au Client et récupérées via un formulaire en ligne. Les
              adresses pourront être conservées par MONBANQUET aussi longtemps
              que cela sera nécessaire pour fournir ses services et se conformer
              à ses obligations légales et contractuelles. La récupération des
              adresses postales peut se faire via formulaire, email ou
              téléphone, et se fait conformément à la Loi Informatique et
              Libertés du 6 janvier 1978, et au Règlement Général sur la
              Protection des Données (RGPD) du 27 avril 2016, afin d’assurer la
              protection, la confidentialité, et la sécurité des données
              personnelles. Pour en savoir plus sur la gestion de vos données
              personnelles et pour exercer vos droits, reportez-vous aux
              mentions légales disponibles sur notre site internet
              www.monbanquet.fr.
            </p>

            <p>
              8 bis 4 Aucune modification d’adresse ne sera possible après
              réception et validation du fichier par MONBANQUET. Tous les colis
              dont les adresses, contacts téléphoniques ou mail sont erronés ou
              non reconnus par notre prestataire de livraison ne pourront pas
              être livrés et seront détruits ou renvoyés à l'expéditeur et ne
              pourront être remboursés au client. Le délai de livraison sera
              communiqué au Client à chaque validation de commande.
            </p>

            <h3>ARTICLE 9 -  EXCLUSION DU DROIT DE RETRACTATION</h3>

            <p>
              Conformément aux dispositions de l’article L. 121-121-8 du Code de
              la consommation, le droit de rétractation ne peut être exercé pour
              les contrats de fourniture de biens susceptibles de se détériorer
              ou de se périmer rapidement.
            </p>

            <p>
              Les Commandes ne sont donc ni reprises ni échangées, sauf en cas
              d’erreur imputable à l’Equipe MONBANQUET, notamment la livraison
              d’une Commande non conforme.
            </p>

            <h3>ARTICLE 10 – RESPONSABILITÉ</h3>

            <p>
              10.1 Il est rappelé que les buffets proposés par l’Equipe
              MONBANQUET sont élaborés par les Artisans Partenaires à partir de
              produits frais.
            </p>

            <p>
              Les buffets doivent être consommés le jour même. L’Equipe
              MONBANQUET ne peut être tenue pour responsable des dommages de
              toute nature, tant matériels qu’immatériels ou corporels, qui
              pourraient résulter d’une mauvaise conservation ou de la mauvaise
              utilisation des produits livrés.
            </p>

            <p>
              10.2 La présence d’allergènes dans la Commande est portée à la
              connaissance du Client. Il appartient à ce dernier de se
              renseigner sur ses allergies ou intolérances à certains produits
              et à rechercher les traces de ces produits au sein des ingrédients
              ou auprès de l’Equipe MONBANQUET. Ainsi l’Equipe MONBANQUET ne
              saurait être tenue responsable en cas de dommages liés à la
              présence d’allergènes.
            </p>

            <p>
              10.3 En cas de retard ou défaillance du livreur, l’Equipe
              MONBANQUET s’engage à tout mettre en œuvre pour assurer une
              nouvelle livraison. Si pour une raison indépendante de sa volonté,
              l’Equipe MONBANQUET se trouve dans l’impossibilité de trouver une
              solution satisfaisante pour le Client dans un délai raisonnable,
              elle procèdera au remboursement intégral du Client.
            </p>

            <p>
              10.4 L’Equipe MONBANQUET a souscrit une assurance Responsabilité
              Civile. Le contrat pourra être communiqué au Client s’il en fait
              la demande.
            </p>

            <p>
              10.5 L’Equipe MONBANQUET ne pourra être tenue responsable, ou
              considérée comme ayant failli à ses obligations prévues dans les
              présentes Conditions Générales, pour toute inexécution liée à un
              cas de force majeure tel que défini par la loi et la jurisprudence
              française.
            </p>

            <p>
              10.6 Le client est seul responsable de toute perte, casse,
              dégradation qui pourrait être causée par les participants et/ou
              par le personnel dont il a la charge, sur les biens et le matériel
              mis à disposition par Monbanquet.fr. Celle-ci fera alors l’objet
              d’une facturation complémentaire, sur la base du tarif de
              remplacement en vigueur.
            </p>

            <h3>ARTICLE 11 - ACCESSIBILITE AU SITE</h3>

            <p>
              Le Site est en principe accessible 24/24h, 7/7j, sauf
              interruption, programmée ou non, pour des besoins de maintenance,
              de problèmes de réseau ou en cas de force majeure.
            </p>

            <p>
              Aucun frais, ni dédommagement ne pourra être réclamé en cas
              d’interruption ou de suspension du Site.
            </p>

            <p>
              Le Client comprend et accepte que l’Equipe MONBANQUET puisse avoir
              à bloquer temporairement ou à limiter l’accès au Site pour des
              questions de maintenance ou de réparation de problème logiciels ou
              de réseau.
            </p>

            <h3>ARTICLE 12- GARANTIES LEGALES</h3>

            <p>
              Article L211-4 du Code de la consommation : « Le vendeur est tenu
              de livrer un bien conforme au contrat et répond des défauts de
              conformité existant lors de la délivrance. Il répond également des
              défauts de conformité résultant de l’emballage, des instructions
              de montage ou de l’installation lorsque celle-ci a été mise à sa
              charge par le contrat ou a été réalisée sous sa responsabilité ».
            </p>

            <p>
              Article L 211-5 du Code de la consommation : « Pour être conforme
              au contrat, le bien doit :
            </p>
            <p>
              1. Etre propre à l’usage habituellement attendu d’un bien
              semblable et, le cas échéant : Correspondre à la description
              donnée par le vendeur et posséder les qualités que celui-ci a
              présentées à l’acheteur sous forme d’échantillon ou de modèle ;
              présenter les qualités qu’un acheteur peut légitimement attendre
              eu égard aux déclarations publiques faites par le vendeur, par le
              producteur ou par son représentant, notamment dans la publicité ou
              l’étiquetage.
            </p>
            <p>
              2. Présenter les caractéristiques définies d’un commun accord par
              les parties ou être propre à tout usage spécial recherché par
              l’acheteur, porté à la connaissance du vendeur et que ce dernier a
              accepté ».
            </p>

            <p>
              Article L211-12 du Code de la consommation : « L’action résultant
              du défaut de conformité se prescrit par deux ans à compter de la
              délivrance du bien ».
            </p>

            <p>
              Article 1641 du Code civil : « Le vendeur est tenu de la garantie
              à raison des défauts cachés de la chose vendue qui la rende
              impropre à l'usage auquel on la destine, ou qui diminuent
              tellement cet usage, que l'acheteur ne l'aurait pas acquise, ou
              n'en aurait donné qu'un moindre prix, s'il les avait connus ».
            </p>

            <p>
              Article 1648 alinéa 1er du Code civil : « L'action résultant des
              vices rédhibitoires doit être intentée par l'acquéreur dans un
              délai de deux ans à compter de la découverte du vice ».
            </p>

            <h3>ARTICLE 13 -  DROIT DE PROPRIÉTÉ INTELLECTUELLE</h3>

            <p>
              13.3 Tout le contenu présent sur le Site, sans aucune exclusion, y
              compris les bases de données et les technologies utilisées par
              l’Equipe MONBANQUET sont la propriété de cette dernière, ou de ses
              Artisans Partenaires, et est protégé par le droit de la propriété
              intellectuelle.
            </p>

            <p>
              13.4 Il est formellement interdit d’extraire, reproduire,
              réutiliser tout ou partie du contenu du Site sauf autorisation
              écrite et expresse de l’Equipe MONBANQUET. Tout accord donné par
              l’Equipe MONBANQUET ou par le titulaire des droits de propriété ne
              crée en aucun cas au profit du Client/Utilisateur un droit
              intangible et pourra être dénoncé à tout moment par l’Equipe
              MONBANQUET ou le titulaire des droits sur simple demande. Le
              Client/Utilisateur devra obtempérer sans délai.
            </p>

            <h3>
              ARTICLE 14 -  PROTECTION DES DONNÉES PERSONNELLES ET POLITIQUE DE
              CONFIDENTIALITE
            </h3>
            <p>
              14.1 L’Equipe MONBANQUET collecte certaines informations
              personnelles des Clients sur le Site (ci-après les
              « Informations ») afin de traiter les Commandes et leur paiement.
              Si le Client s’oppose à la collecte des Informations, la Commande
              ne pourra être effectuée.
            </p>

            <p>
              D’une manière générale, les Informations collectées permettent de
              personnaliser et d’améliorer la qualité du Site et la navigation
              des Clients.
            </p>

            <p>
              14.2 L’Equipe MONBANQUET est responsable du traitement des
              Informations des Clients.
            </p>

            <p>
              Conscient de ce que la collecte et le traitement des données
              personnelles sur Internet doivent respecter les droits
              fondamentaux des personnes, l’Equipe MONBANQUET s’engage à ce que
              tout traitement de données nominatives sur le Site soit conforme à
              la loi n°78-17 du 6 janvier 1978 relative à l’informatique, aux
              fichiers et aux libertés.
            </p>

            <p>
              Le Client dispose d’un droit d’accès et d’un droit de
              rectification aux données personnelles le concernant. Le Client
              peut exercer ce droit à tout moment en envoyant une demande de
              modification, de mise à jour ou d’effacement des Informations le
              concernant à l’adresse contact@monbanquet.fr.
            </p>

            <p>
              14.3 Dans le cadre du traitement des Informations dont l’objet est
              mentionné ci-dessus, l’Equipe MONBANQUET divulgue le contenu
              approprié à ses Artisans Partenaires et aux prestataires chargés
              d’assurer la livraison des Commandes.
            </p>

            <p>
              14.4 L’Equipe MONBANQUET divulgue nécessairement les Informations
              auprès des autorités administratives ou judiciaires qui le lui
              demanderaient sur injonction.
            </p>

            <p>
              14.5 Le Site comporte des cookies. Un cookie est un fichier texte
              enregistré sur l’ordinateur du Client lors de la visite du Site ou
              la consultation d’une publicité. Ils ont notamment pour but de
              collecter des informations relatives à la navigation du Client sur
              le Site et de lui proposer des services personnalisés.
            </p>

            <p>
              Les cookies sont sans dommage pour l’ordinateur du Client. Le
              Client est libre d’accepter ou de refuser les cookies en
              configurant son navigateur. Les cookies ne sont en aucun cas
              utilisés pour collecter des données à caractère personnel.
            </p>

            <h3>ARTICLE 15 -  PUBLICITÉ</h3>

            <p>
              L’Equipe MONBANQUET se réserve la faculté d’insérer sur toute page
              du Site tous messages à caractère publicitaire ou promotionnel
              sous la forme et dans les conditions dont elle sera seule
              décisionnaire.
            </p>

            <h3>ARTICLE 16-  DROIT APPLICABLE – LITIGE</h3>

            <p>
              Les présentes Conditions Générales seront exécutées et
              interprétées conformément au droit français, à l’exclusion de tout
              autre réglementation.
            </p>

            <p>
              En cas de litige ou de réclamation, l’Equipe MONBANQUET proposera
              au Client de trouver une solution à travers une médiation.
            </p>

            <p>
              En cas d’échec de la mesure de médiation, les Tribunaux du ressort
              de la Cour d'appel de Paris seront seuls compétents.
            </p>

            <h3>ARTICLE 17 -  INVALIDITE PARTIELLE</h3>

            <p>
              Si une ou plusieurs dispositions des présentes Conditions
              Générales devien(nen)t nulle(s), inopposable(s), caduque(s),
              illégale(s) ou inapplicable(s) du fait d'une loi, d'un règlement
              ou à la suite d'une décision devenue définitive d'une juridiction
              compétente, les autres stipulations des présentes Conditions
              Générales garderont toute leur force et leur portée.
            </p>

            <h3>ARTICLE 18 -  INTEGRALITE</h3>

            <p>
              Les dispositions des présentes Conditions Générales et l’ensemble
              des données relatives à la Commande communiquées aux Clients
              expriment l'intégralité de l'accord conclu entre les Clients et
              l’Equipe MONBANQUET. Elles prévalent sur toute proposition,
              échange de lettres antérieures et postérieures à la conclusion des
              présentes, ainsi que sur toute autre disposition figurant dans les
              documents échangés entre les parties et relatifs à l'objet des
              Conditions Générales, sauf avenant dûment signé par les
              représentants des deux parties.
            </p>

            <h3>ARTICLE 19 -  TOLERANCE</h3>

            <p>
              La simple tolérance du non-respect d’une disposition des
              Conditions Générales par l’une des parties ne constituera en aucun
              cas un abandon de droit ou une renonciation, ni une modification
              des Conditions Générales. Le fait que l’une des parties ne mette
              pas en œuvre l’un des droits qui lui est concédé par les
              Conditions Générales ne veut pas dire qu’elle renonce pour
              l’avenir à ce droit.
            </p>
          </div>
        </div>
        <Footer />
      </StyledCgvPage>
    )
  }
}

export default CgvPage
